<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="大病人员"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="100px">
                <el-form-item label="单位名称" >
                    <el-input placeholder="请输入" v-model="search.DWMC"  clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="身份证号" >
                    <el-input placeholder="请输入" v-model="search.SFZH"  clearable>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="mini" border v-loading="loading" width="100%" height="100%">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column align="center" label="单位名称" prop="DWMC"/>
                    <el-table-column align="center" label="姓名" prop="XM"/>
                    <el-table-column align="center" label="身份证号" prop="SFZH"/>
                    <el-table-column align="center" label="备注" prop="desc" />
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    import formatter from "@/data/formatter"

    export default {
        data() {
            return {
                search: {},
                form: {},
                formatter: formatter,
                loading: false,
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20,
                },
                menuData:[]
            }
        },
        methods: {
            handleRowChange(row){
                this.form = row
                this.dialogVisible = true
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.qxVisible = false
                this.dialogVisible = false
                this.loading = true
                const resp = await this.$http.get(`/statistics/dabing/data?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px 0 !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
